import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/authContext";
import { useForm } from "../utility/hooks";
import { useMutation, useQuery } from "@apollo/client";
import { gql } from "graphql-tag";
import { useNavigate } from "react-router-dom";
import { BarChart, LineChart } from "@mui/x-charts";
import {
  TextField,
  Button,
  Stack,
  Alert,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Box,
  CardContent,
  Typography,
} from "@mui/material";

const TIPSTER_STATS = gql`
  mutation GetTipsterStats(
    $sport: String!
    $category: String!
    $tipsterId: String!
  ) {
    getTipsterStats(sport: $sport, category: $category, tipsterId: $tipsterId) {
      _id
      url
      handle
      tracked {
        last_10_results_array
        threemonths {
          yield_percentage
          profit
          category_data {
            name
            yield
            profit
            tipNum
            win_ratio
          }
          sport_data {
            name
            yield
            profit
            tipNum
            win_ratio
          }
        }
        onemonths {
          yield_percentage
          profit
          category_data {
            name
            yield
            profit
            tipNum
            win_ratio
          }
          sport_data {
            name
            yield
            profit
            tipNum
            win_ratio
          }
        }
        lasttens {
          yield_percentage
          profit
          category_data {
            name
            yield
            profit
            tipNum
            win_ratio
          }
          sport_data {
            name
            yield
            profit
            tipNum
            win_ratio
          }
        }
      }
    }
  }
`;

const LIVE_TIPS = gql`
  query {
    liveTips {
      _id
      url
      odds
      tier
      action
      tipBets {
        market
        bet
      }
      tipster {
        handle
        profilePic
      }
      tipsterID
      betUnits
      tipUnits
      fixtureDets {
        homeTeam
        awayTeam
        event
        sport
        region
        eventDate
        competition
      }
      event_status
      originalText
    }
  }
`;

const ADD_BET = gql`
  mutation AddTransaction($transaction: InputTransaction!) {
    addTransaction(inputTransaction: $transaction)
  }
`;

function Login(props) {
  let navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [errors, setErrors] = useState([]);
  const [selectedTip, setSelectedTip] = useState("");
  const [tipDetails, setTipDetails] = useState(null);
  const [tipsterDetails, setTipsterDetails] = useState(null);
  const [msg, setMsg] = useState("");
  const { onChange, onSubmit, values, setValues } = useForm(addBetCallback, {
    amount: 0.0,
    betUnits: null,
    category: "investing",
    status: "pending",
    transactionType: "sports-bet",
    transactionDetails: "",
    account: "",
    stake: 0,
    odds: 0,
    commission: 0,
  });

  function addBetCallback() {
    addBet();
  }

  function getTipsterHandler() {
    console.log("f", tipDetails);
    getTipster();
  }

  const { data, loading: tips_loading } = useQuery(LIVE_TIPS);

  const [getTipster] = useMutation(TIPSTER_STATS, {
    update(proxy, { data: { getTipsterStats } }) {
      console.log(getTipsterStats);
      if (getTipsterStats) {
        setTipsterDetails(getTipsterStats);
      }
    },
    onError({ graphQLErrors }) {
      setErrors(graphQLErrors);
    },
    variables: {
      tipsterId: tipDetails ? tipDetails.tipsterID : "",
      sport: tipDetails
        ? tipDetails.fixtureDets.length > 1
          ? "combo pick"
          : tipDetails.fixtureDets[0].sport.toLowerCase()
        : "",
      category: tipDetails
        ? tipDetails.fixtureDets.length > 1
          ? "combo pick - combo pick"
          : `${tipDetails.fixtureDets[0].sport.toLowerCase()} - ${tipDetails.fixtureDets[0].competition.toLowerCase()}`
        : "",
    },
  });

  const [addBet, { loading: bet_loading }] = useMutation(ADD_BET, {
    update(proxy, { data: { addTransaction } }) {
      console.log(addTransaction);
      if (addTransaction) {
        window.location.reload();
      }
    },
    onError({ graphQLErrors }) {
      setErrors(graphQLErrors);
    },
    variables: {
      transaction: {
        transactionDate: Math.floor(new Date().getTime() / 1000),
        amount: parseFloat(values.amount),
        category: values.category,
        status: values.status,
        currency: "GBP",
        transactionType: values.transactionType,
        transactionDetails: JSON.stringify({
          stake: parseFloat(values.stake),
          odds: parseFloat(values.odds),
          commission: values.commission,
          tipId: selectedTip,
        }),
        account: values.account,
        betUnits:
          (values.betUnits === null || values.betUnits === "") && tipDetails
            ? parseFloat(tipDetails.betUnits)
            : values.betUnits === null || values.betUnits === ""
            ? parseFloat(values.betUnits)
            : null,
      },
    },
  });

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    if (data && window.location.href.includes("tipId=")) {
      console.log(window.location.href.split("tipId=")[1]);
      const tips = data.liveTips.filter(function (tip) {
        return tip._id === window.location.href.split("tipId=")[1];
      });

      setSelectedTip(tips[0]._id);
      setTipDetails(tips[0]);
      setMsg(`${tips[0].originalText} for ${tips[0].betUnits}`);
    }
  }, [data, user, navigate]);
  console.log(tips_loading);
  if (tips_loading) {
    return <>Loading Tips...</>;
  }
  let last_ten_graph = [];
  let last_ten_sum = 0;
  if (tipsterDetails) {
    last_ten_graph = [
      {
        data: tipsterDetails.tracked.last_10_results_array,
        label: "Units",
      },
    ];
    last_ten_sum = tipsterDetails.tracked.last_10_results_array.reduce(
      (partialSum, a) => partialSum + a,
      0
    );
    console.log(last_ten_graph);
  }
  console.log(values);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Stack spacing={2} paddingBottom={2}>
          <InputLabel id="demo-simple-select-label" fullWidth>
            Tip ID
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedTip !== "" ? selectedTip : ""}
            label="Tip ID"
            onChange={(e) => {
              console.log(e.target.value);
              setSelectedTip(e.target.value);
              const tips = data.liveTips.filter(function (tip) {
                return tip._id === e.target.value;
              });
              setTipDetails(tips[0]);
              setMsg(`${tips[0].originalText} for ${tips[0].betUnits}`);
            }}
            fullWidth
          >
            {data.liveTips.map((tip) => {
              return <MenuItem value={tip._id}>{tip._id}</MenuItem>;
            })}
          </Select>
          <TextField
            label="Tip"
            name="tip"
            disabled
            value={tipDetails ? tipDetails.originalText : ""}
            fullWidth
          />

          <TextField label="Msg" name="msg" disabled value={msg} fullWidth />

          <TextField
            label="Tipster"
            name="tipster"
            disabled
            value={tipDetails ? tipDetails.tipster.handle : ""}
            fullWidth
          />
          <TextField
            label="Tip Units"
            name="tip-units"
            disabled
            value={tipDetails ? tipDetails.tipUnits : ""}
            fullWidth
          />
          <TextField
            label="Bet Units"
            name="betUnits"
            value={
              tipDetails && values.betUnits === null
                ? tipDetails.betUnits
                : values.betUnits
            }
            fullWidth
            onChange={onChange}
          />
        </Stack>
        <h3>Add Bet</h3>
        <Stack spacing={2} paddingBottom={2}>
          <TextField label="account" name="account" onChange={onChange} />
          <TextField label="stake" name="stake" onChange={onChange} />
          <TextField label="odds" name="odds" onChange={onChange} />
          <TextField label="commission" name="commission" onChange={onChange} />
        </Stack>
        {errors.map(function (error) {
          return <Alert severity="error">{error.message}</Alert>;
        })}
        <Button variant="contained" onClick={onSubmit}>
          Send Tip
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack spacing={2} paddingBottom={2}></Stack>
        <TextField
          label="Tipster Handle"
          name="tipster-handle-units"
          disabled
          value={tipsterDetails ? tipsterDetails.handle : ""}
          fullWidth
        />
        <Button variant="contained" onClick={getTipsterHandler}>
          Get Tipster
        </Button>
        {tipsterDetails && (
          <>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Box
                    sx={{
                      bgcolor: "background.paper",
                      boxShadow: 1,
                      borderRadius: 2,
                      p: 2,
                    }}
                  >
                    <BarChart
                      series={last_ten_graph}
                      height={290}
                      xAxis={[
                        {
                          data: [
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                            "6",
                            "7",
                            "8",
                            "9",
                            "10",
                          ],
                          scaleType: "band",
                        },
                      ]}
                      margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box
                    sx={{
                      bgcolor: "background.paper",
                      boxShadow: 1,
                      borderRadius: 2,
                      p: 2,
                    }}
                  >
                    <TextField
                      label="Bet Units"
                      name="bet-units"
                      disabled
                      value={tipDetails ? `Profit: ${last_ten_sum}` : ""}
                      fullWidth
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      Sports 3 Months
                    </Typography>

                    <Typography variant="header">
                      {Math.round(
                        (tipsterDetails.tracked["threemonths"].sport_data
                          .profit +
                          Number.EPSILON) *
                          100
                      ) / 100}{" "}
                      Units
                      <br />
                      {Math.round(
                        (tipsterDetails.tracked["threemonths"].sport_data
                          .yield +
                          Number.EPSILON) *
                          100
                      ) / 100}{" "}
                      %
                    </Typography>
                  </CardContent>
                </Grid>
                <Grid item xs={4}>
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      Sports 1 Month
                    </Typography>

                    <Typography variant="header">
                      {Math.round(
                        (tipsterDetails.tracked["onemonths"].sport_data.profit +
                          Number.EPSILON) *
                          100
                      ) / 100}{" "}
                      Units
                      <br />
                      {Math.round(
                        (tipsterDetails.tracked["onemonths"].sport_data.yield +
                          Number.EPSILON) *
                          100
                      ) / 100}{" "}
                      %
                    </Typography>
                  </CardContent>
                </Grid>
                <Grid item xs={12} md={4}>
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      Sports Last 10
                    </Typography>

                    <Typography variant="header">
                      {Math.round(
                        (tipsterDetails.tracked["lasttens"].sport_data.profit +
                          Number.EPSILON) *
                          100
                      ) / 100}{" "}
                      Units
                      <br />
                      {Math.round(
                        (tipsterDetails.tracked["lasttens"].sport_data.yield +
                          Number.EPSILON) *
                          100
                      ) / 100}{" "}
                      %
                    </Typography>
                  </CardContent>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      Category 3 Months
                    </Typography>

                    <Typography variant="header">
                      {tipsterDetails.tracked["threemonths"].category_data
                        ? Math.round(
                            (tipsterDetails.tracked["threemonths"].category_data
                              .profit +
                              Number.EPSILON) *
                              100
                          ) / 100
                        : "No Data"}{" "}
                      Units
                      <br />
                      {tipsterDetails.tracked["threemonths"].category_data
                        ? Math.round(
                            (tipsterDetails.tracked["threemonths"].category_data
                              .yield +
                              Number.EPSILON) *
                              100
                          ) / 100
                        : "No Data"}
                      {" % "}
                    </Typography>
                  </CardContent>
                </Grid>
                <Grid item xs={12} md={4}>
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      Category 1 Month
                    </Typography>

                    <Typography variant="header">
                      {tipsterDetails.tracked["onemonths"].category_data
                        ? Math.round(
                            (tipsterDetails.tracked["onemonths"].category_data
                              .profit +
                              Number.EPSILON) *
                              100
                          ) / 100
                        : "No Data"}{" "}
                      Units
                      <br />
                      {tipsterDetails.tracked["onemonths"].category_data
                        ? Math.round(
                            (tipsterDetails.tracked["onemonths"].category_data
                              .yield +
                              Number.EPSILON) *
                              100
                          ) / 100
                        : "No Data"}
                      {" % "}
                    </Typography>
                  </CardContent>
                </Grid>
                <Grid item xs={4}>
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      Category Last 10
                    </Typography>

                    <Typography variant="header">
                      {tipsterDetails.tracked["lasttens"].category_data
                        ? Math.round(
                            (tipsterDetails.tracked["lasttens"].category_data
                              .profit +
                              Number.EPSILON) *
                              100
                          ) / 100
                        : "No Data"}{" "}
                      Units
                      <br />
                      {tipsterDetails.tracked["lasttens"].category_data
                        ? Math.round(
                            (tipsterDetails.tracked["lasttens"].category_data
                              .yield +
                              Number.EPSILON) *
                              100
                          ) / 100
                        : "No Data"}
                      {" % "}
                    </Typography>
                  </CardContent>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default Login;
