import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/authContext";
import { useForm } from "../utility/hooks";
import { TextField, Button, Container, Stack, Alert } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { gql } from "graphql-tag";
import { useNavigate } from "react-router-dom";

const ADD_URL = gql`
  mutation AddSnifferUrl($url: String!) {
    addSnifferUrl(url: $url)
  }
`;

function HomePage() {
  let navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [errors, setErrors] = useState([]);
  const { onChange, onSubmit, values } = useForm(addUrlCallback, {
    url: "",
  });

  function addUrlCallback() {
    addUrl();
  }
  const [addUrl] = useMutation(ADD_URL, {
    update(proxy, { data: { addSnifferUrl } }) {
      console.log(addSnifferUrl);
      if (addSnifferUrl) {
        window.location.reload();
      }
    },
    onError({ graphQLErrors }) {
      setErrors(graphQLErrors);
    },
    variables: {
      url: values.url,
    },
  });

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);
  return (
    <Container spacing={2} maxWidth="m">
      <Stack spacing={2} paddingBottom={2}>
        <TextField
          label="Sniffer URL"
          name="url"
          value={values.url}
          fullWidth
          onChange={onChange}
        />
        {errors.map(function (error) {
          return <Alert severity="error">{error.message}</Alert>;
        })}
        <Button variant="contained" onClick={onSubmit}>
          Send URL
        </Button>
      </Stack>
    </Container>
  );
}

export default HomePage;
