import { AppBar, Box, Toolbar, Typography, Button } from "@mui/material";
import { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
function Navbar() {
  const { user, logout } = useContext(AuthContext);
  let navigate = useNavigate();
  const onLogout = () => {
    logout();
    navigate("/");
  };
  useEffect(() => {
    console.log(window.location.href);
    if (window.location.href.includes("login") && user) {
      navigate("/");
    }
  }, [user, navigate]);
  console.log(user);
  return (
    <Box sx={{ flewGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h5" component="div">
            <Link to="/" style={{ textDecoration: "nine", color: "white" }}>
              HomePage
            </Link>
          </Typography>
          <Box alignItems={"right"} sx={{ flexGrow: 1, textAlign: "right" }}>
            {user ? (
              <>
                <Link
                  to="/tippingform"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Tipping Form
                </Link>
                <Button
                  style={{ textDecoration: "none", color: "white" }}
                  onClick={onLogout}
                >
                  LOGOUT
                </Button>
              </>
            ) : (
              <>
                <Link
                  to="/login"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Login
                </Link>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Navbar;
