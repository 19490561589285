import "./App.css";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/homepage";
import Login from "./pages/login";
import Navbar from "./components/Navbar";
import TippingForm from "./pages/tippingform";
function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/tippingform" element={<TippingForm />} />
      </Routes>
    </div>
  );
}

export default App;
