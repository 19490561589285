import { useContext, useState } from "react";
import { AuthContext } from "../context/authContext";
import { useForm } from "../utility/hooks";
import { useMutation } from "@apollo/client";
import { gql } from "graphql-tag";
import { useNavigate } from "react-router-dom";
import bcrypt from "bcryptjs";
import { TextField, Button, Container, Stack, Alert } from "@mui/material";

const LOGIN_USER = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`;

function Login(props) {
  const context = useContext(AuthContext);
  console.log(context);
  let navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  function loginUserCallback() {
    loginUser();
  }
  const { onChange, onSubmit, values } = useForm(loginUserCallback, {
    email: "",
    password: "",
  });

  const [loginUser, { loading }] = useMutation(LOGIN_USER, {
    update(proxy, { data: { login: userData } }) {
      console.log(userData);
      context.login(userData);
      navigate("/");
    },
    onError({ graphQLErrors }) {
      setErrors(graphQLErrors);
    },
    variables: {
      email: values.email,
      password: bcrypt.hashSync(values.password, 10),
    },
  });

  return (
    <Container spacing={2} maxWidth="sm">
      <h3>LOGIN</h3>
      <Stack spacing={2} paddingBottom={2}>
        <TextField label="email" name="email" onChange={onChange} />
        <TextField
          label="password"
          name="password"
          type="password"
          onChange={onChange}
        />
      </Stack>
      {errors.map(function (error) {
        return <Alert severity="error">{error.message}</Alert>;
      })}
      <Button variant="contained" onClick={onSubmit}>
        Login
      </Button>
    </Container>
  );
}

export default Login;
